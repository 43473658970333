<template>
  <div class="auth" id="forgot-pwd">
    <section class="hero is-fullheight">
      <div class="header">
        Bienvenue sur Maviepro
      </div>
      <div class="hero-body">
        <div class="auth-block">
          <div class="auth-form">
            <form @submit.prevent="run">
              <p>
                Vous avez oublié votre mot de passe ?<br>
                Veuillez entrer votre adresse e-mail et nous vous enverrons un lien pour le modifier.
              </p>
              <b-field>
                <b-input type="email" v-model="email" required size="large"
                  icon="fas fa-at"
                  placeholder="Adresse e-mail"></b-input>
              </b-field>
              <b-button type="is-primary" v-bind:disabled="email.length < 5"  native-type="submit" expanded>
                Recevoir un lien
              </b-button>
            </form>
          </div>
          <router-link to="/login" class="auth-link">
            Retour à la Connexion
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { http } from '@/services/api.service'
import { ToastProgrammatic as Toast } from 'buefy'

export default {
  name: 'ForgotPassword',
  data () {
    return {
      email: ''
    }
  },
  methods: {
    run () {
      const { email } = this
      http.post('/auth/forgot-password', { email }).then(res => {
        Toast.open({
          message: 'Nous vous avons envoyé un e-mail afin de poursuivre la procédure de récupération de mot de passe.<br>Merci de vérifier votre boîte de réception',
          duration: 10000,
          position: 'is-top',
          type: 'is-success'
        })
        this.$router.push('/login')
      }).catch(err => {
        Toast.open({
          message: err.response.data,
          duration: 5000,
          position: 'is-bottom',
          type: 'is-danger'
        })
      })
    }
  }
}
</script>

<style lang="scss">
body {
  background: whitesmoke;
}
.hero-body {
  justify-content: center;
  flex-direction: column;
}

#forgot-pwd {
  .hero-body {
    background-color: rgb(248, 247, 247);
    color: #000;
    padding: 1.5rem
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    button {
      border-radius: 1rem;
      background-color: rgb(45,93,251);
    }
  }

  input {
    border-radius: 1rem;
  }

  .auth-form {
    color: #000;
    box-shadow: none;
    padding: 0;
  }

  .header {
    background: #2F4AF4;
    color: #fff;
    height: 9rem;
    padding: 1.5rem;
    display: flex;
    align-items: flex-end;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 600;
    letter-spacing: 0.025em;
  }
}

.login {
  background: white;
  padding: 25px;
}
.login-hr {
  border-bottom: 1px solid black;
}

.forgot-password {
  display: inline-block;
  margin: 10px 0 !important;
}
</style>
